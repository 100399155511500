import React from 'react';
import { ResourceLocation } from 'generated/data-contracts';
import { ProductCardMaster, SanitizedProductType } from '../types';

export interface UseProductCardContextReturnType {
	key: string;
	product: SanitizedProductType;
	activeMaster: ProductCardMaster | undefined;
	setActiveMaster: (id: string | undefined) => void;
	isBeingHovered: boolean;
	setIsBeingHovered: React.Dispatch<React.SetStateAction<boolean>>;
	isSkeleton: boolean;
	updateNavParams: () => void;
	isActiveForCmi: boolean;
	productUrl: string;
	route: ResourceLocation;
}

export const ProductCardContextContext = React.createContext<UseProductCardContextReturnType | null>(null);

export const useProductCardContext = (): UseProductCardContextReturnType => {
	const context = React.useContext(ProductCardContextContext);

	if (!context) {
		throw new Error('useProductCardContext must be used within a ProductCardContextProvider');
	}

	return context;
};
