import React, { useEffect, useState } from 'react';
import { isEmpty, uniqueId } from 'lodash';
import _ from 'lodash';
import { useBasketFamilyQuantitiesQuery } from 'api/basket';
import {
	convertFamilyIdToStyleId,
	convertStyleIdToFamilyId,
} from 'components/features/ProductDetail/productDetailUtils';
import { formatProductUrlParams } from 'components/fragments/Basket';
import {
	Area,
	AssortmentType,
	FavouriteListLineProductKeyModel,
	ProductFamilyId,
	ProductItemResponse,
	ProductMasterId,
	ProductOrderDetails,
	ProductOrderType,
	ResourceLocation,
	SplashResponse,
	SplashType,
} from 'generated/data-contracts';
import {
	ProductCardMaster,
	ProductCardMasters,
	ProductCardPriceDetails,
	ProductCardSplashes,
	SanitizedProductType,
} from '../../types';
import { ProductCardContextContext } from '../ProductCardContext';

interface UseProductCardContextReturnType {
	key: string;
	product: SanitizedProductType;
	activeMaster: ProductCardMaster | undefined;
	setActiveMaster: (id: string | undefined) => void;
	isBeingHovered: boolean;
	setIsBeingHovered: React.Dispatch<React.SetStateAction<boolean>>;
	isSkeleton: boolean;
	updateNavParams: () => void;
	isActiveForCmi: boolean;
	productUrl: string;
	route: ResourceLocation;
}

interface ProductCardContextContextProps {
	product?: ProductItemResponse;
	isSkeleton?: boolean;
	updateNavParams?: () => void;
	setFavouritedMasterAsDefaultMaster?: boolean;
}

const useSanitizeSplashes = (product?: ProductItemResponse): ProductCardSplashes => {
	const { data: familyQuantities } = useBasketFamilyQuantitiesQuery();
	if (!product) {
		return {};
	}

	const splashes = product.splashes.reduce<Partial<Record<SplashType, SplashResponse>>>((acc, cur) => {
		if (!cur.type) return acc;
		return {
			...acc,
			[cur.type]: cur ?? undefined,
		};
	}, {});
	const orderDetails = product.orderDetails.reduce<Partial<Record<ProductOrderType, ProductOrderDetails>>>(
		(acc, cur) => {
			if (!cur.type) return acc;
			return {
				...acc,
				[cur.type]: cur ?? undefined,
			};
		},
		{},
	);
	let quantityInBasket: number | undefined;
	if (product.id) {
		quantityInBasket = familyQuantities?.[product.id] ?? 0;
	}
	return {
		[SplashType.Cmi]: splashes[SplashType.Cmi] !== undefined,
		[SplashType.DeliveryMonth]: splashes[SplashType.DeliveryMonth],
		[SplashType.Discount]: splashes[SplashType.Discount],
		[SplashType.FashionForward]: splashes[SplashType.FashionForward] !== undefined,
		[SplashType.NeverOutOfStock]: splashes[SplashType.NeverOutOfStock],
		[SplashType.NeverOutOfStockType]: splashes[SplashType.NeverOutOfStockType],
		[ProductOrderType.Inbound]: orderDetails[ProductOrderType.Inbound],
		[ProductOrderType.SevenMonthsDelivered]: orderDetails[ProductOrderType.SevenMonthsDelivered],
		[ProductOrderType.RecentlyDelivered]: orderDetails[ProductOrderType.RecentlyDelivered],
		quantityInBasket,
	};
};

const sanitizeMasters = (product?: ProductItemResponse): ProductCardMasters => {
	if (!product) {
		return {};
	}

	const favListKeyType = ({ deliveryDate, masterId, isFreeAssortment }: FavouriteListLineProductKeyModel): string =>
		`${deliveryDate}-${masterId}-${isFreeAssortment}`;
	const favListCases =
		product.favouriteListCases?.reduce((acc, cur) => {
			return {
				...acc,
				[favListKeyType(cur)]: true,
			};
		}, {}) ?? {};
	return product.masters.reduce<ProductCardMasters>((acc, curr): ProductCardMasters => {
		const [url, searchParams] = (curr.url ?? '').split('?');
		const queryParams = formatProductUrlParams({
			searchParams: new URLSearchParams(searchParams),
			deliveryDate: curr?.deliveryDate,
			styleOptionNumber: curr?.id,
			selectedView: curr?.isFreeAssortment ? AssortmentType.FreeAssortments : AssortmentType.Boxes,
		});
		const productUrl = `${url}?${queryParams.toString()}`;
		const isFavourite =
			favListCases[
				favListKeyType({
					deliveryDate: curr.deliveryDate,
					isFreeAssortment: curr.isFreeAssortment,
					masterId: curr.id,
				})
			];

		return {
			...acc,
			[curr.id]: {
				colour: curr.colour,
				deliveryDate: curr.deliveryDate,
				id: curr.id,
				isFavourite: isFavourite,
				isFreeAssortment: curr.isFreeAssortment,
				primaryImage: curr.primaryImage,
				url: productUrl,
				name: curr.name,
				images: curr.images,
			},
		};
	}, {});
};

const useSanitizeProduct = (
	product: ProductItemResponse | undefined,
	setFavouritedMasterAsDefaultMaster: boolean,
): SanitizedProductType => {
	const splashes: ProductCardSplashes = useSanitizeSplashes(product);
	return React.useMemo(() => {
		let familyId: ProductFamilyId = '';
		let styleNumber: string = '';
		let styleName: string = '';
		const masters: Record<ProductMasterId, ProductCardMaster> = sanitizeMasters(product);
		let priceDetails: ProductCardPriceDetails | undefined = undefined;
		let orderedMastersList: string[] = product?.masters.map((r) => r.id) ?? [];
		if (!isEmpty(product)) {
			styleName = product.name;
			priceDetails = product.pricingDetails;
			familyId = convertStyleIdToFamilyId(product.id);
			styleNumber = convertFamilyIdToStyleId(product.styleNumber);
			if (setFavouritedMasterAsDefaultMaster) {
				orderedMastersList = _.uniq(
					product.favouriteListCases.map((r) => r.masterId).concat(product.masters.map((r) => r.id)),
				);
			}
		}
		return {
			familyId,
			masters,
			priceDetails,
			styleNumber,
			styleName,
			splashes,
			orderedMastersList,
		};
	}, [product, splashes, setFavouritedMasterAsDefaultMaster]);
};

const useProductCardContextState = ({
	product,
	isSkeleton = product === undefined,
	updateNavParams = () => {},
	setFavouritedMasterAsDefaultMaster = false,
}: ProductCardContextContextProps): UseProductCardContextReturnType => {
	const key = React.useRef(uniqueId(`product-card-`)).current;
	const sanitizedProduct = useSanitizeProduct(product, setFavouritedMasterAsDefaultMaster);
	const initialMaster = React.useMemo(
		() => sanitizedProduct.masters[sanitizedProduct.orderedMastersList[0]],
		[sanitizedProduct],
	);
	const [activeMaster, setActiveMaster] = useState<ProductCardMaster | undefined>();
	const [isBeingHovered, setIsBeingHovered] = useState<boolean>(false);

	useEffect(() => {
		if (!activeMaster && !isEmpty(initialMaster)) {
			setActiveMaster(initialMaster);
		}
	}, [activeMaster, initialMaster]);

	let productUrl = '';

	if (activeMaster) {
		const [url, searchParams] = activeMaster.url.split('?');
		const queryParams = formatProductUrlParams({
			searchParams: new URLSearchParams(searchParams),
			deliveryDate: activeMaster?.deliveryDate,
			styleOptionNumber: activeMaster?.id,
			selectedView: activeMaster?.isFreeAssortment ? AssortmentType.FreeAssortments : AssortmentType.Boxes,
		});
		productUrl = `${url}?${queryParams.toString()}`;
	}

	const route: ResourceLocation = {
		area: Area.Product,
		productFamilyId: sanitizedProduct?.familyId,
		externalRoute: productUrl,
	};

	const isActiveForCmi = sanitizedProduct.splashes[SplashType.Cmi] === true;

	return {
		key,
		product: sanitizedProduct,
		activeMaster,
		setActiveMaster: (id: string | undefined) => setActiveMaster(id ? sanitizedProduct.masters[id] : undefined),
		isSkeleton,
		isBeingHovered,
		setIsBeingHovered,
		updateNavParams,
		productUrl,
		isActiveForCmi,
		route,
	};
};

type ProductCardProviderProps = ProductCardContextContextProps & {
	children: ((props: UseProductCardContextReturnType) => React.ReactNode) | React.ReactNode;
};

export const ProductCardContextProvider: React.FunctionComponent<ProductCardProviderProps> = ({
	children,
	...props
}: ProductCardProviderProps) => {
	const value = useProductCardContextState(props);

	return (
		<ProductCardContextContext.Provider value={value}>
			{typeof children === 'function' ? children(value) : children}
		</ProductCardContextContext.Provider>
	);
};
