import React, {
	ChangeEventHandler,
	FC,
	FocusEventHandler,
	KeyboardEventHandler,
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { FreeAssortmentStockPopup } from 'components/features/ProductDetail/ProductBasket/V2/Desktop/FreeAssortmentStockPopup';
import DisabledCell from 'components/features/ProductDetail/ProductBasket/V2/shared/DisabledCell';
import { IncomingVariantIcon, useBasketRowContext, useStockAvailabilityWithQuery } from 'components/fragments/Basket';
import { IncomingQuantityBadge } from 'components/fragments/Basket/Desktop/Shared/IncomingQuantityBadge';
import { DragHandle } from 'components/shared/DragToCopyContext/DragHandle';
import { StockAvailabilityDesktop } from 'components/shared/StockAvailabilityDesktop';
import { BasketLineRequest, NewBasketProductMasterResponse } from 'generated/data-contracts';
import { useDebounce } from 'helpers/useDebounce';
import { clampToMax, clampToMinOrZero, parseToNumber } from '../../../helpers';
import styles from './FreeAssortmentQuantityInput.module.scss';

interface FreeAssortmentQuantityInputProps {
	quantity: number;
	master?: NewBasketProductMasterResponse;
	queryInfo: BasketLineRequest;
	shouldUseAvailability: boolean;
	isDisabled?: boolean;
	closePopup?: () => void;
	inboundQuantity: string | undefined;
}

export const FreeAssortmentQuantityInput: FC<FreeAssortmentQuantityInputProps> = ({
	isDisabled,
	shouldUseAvailability,
	quantity,
	master,
	queryInfo,
	closePopup,
	inboundQuantity,
}) => {
	const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
	const [isActive, setIsActive] = useState(false);
	const [value, setValue] = useState<number>(quantity);
	const [flash, setFlash] = useState(false);

	const { isSDO, onQuantityUpdate, lookups, bundle } = useBasketRowContext();

	const { availability, availableStockLeft, realTimeTotalInBasket, hasAvailability } = useStockAvailabilityWithQuery({
		assortmentId: queryInfo.variantId ?? undefined,
		quantityInBasket: queryInfo.quantity,
		inputValue: value,
		shouldUseStockAvailability: shouldUseAvailability,
		lookups,
	});

	const minValue: number = bundle.minimumQuantity ?? 0;
	const maxValue = shouldUseAvailability ? availableStockLeft : Infinity;

	const debouncedSubmitValue = useDebounce(
		React.useCallback(
			(newValue = value): void => {
				const clampedValue = clampToMinOrZero(newValue, minValue);
				setValue(clampedValue);

				if (clampedValue === quantity) return;

				onQuantityUpdate({ ...queryInfo, quantity: clampedValue })
					.then((data) => {
						setFlash(true);
						setTimeout(() => {
							setFlash(false);
						}, 500);
						if (data?.quantity !== undefined) {
							setValue(data.quantity ?? 0);
						}
					})
					.catch(() => {
						setValue(quantity);
					});
			},
			[minValue, onQuantityUpdate, quantity, queryInfo, value],
		),
		500,
	);

	useEffect(() => {
		if (isEqual(quantity, value)) return;
		setValue(quantity);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quantity]);

	const updateValue: ChangeEventHandler<HTMLInputElement> = (event) => {
		const parsedInputValue = parseToNumber(event.target.value);
		const clampedInputValue = clampToMax(parsedInputValue, maxValue);

		setValue(clampedInputValue); //immediately sets quantity to provide instant feedback

		debouncedSubmitValue(clampedInputValue);
	};

	const updateValueOnEnterKey: KeyboardEventHandler<HTMLInputElement> = (event) => {
		if (event.key !== 'Enter') return;
		closePopup?.();
		debouncedSubmitValue();
	};

	const updateValueOnBlur: FocusEventHandler<HTMLInputElement> = (): void => {
		debouncedSubmitValue();
		setIsActive(false);
	};

	const setActiveOnFocus: FocusEventHandler<HTMLInputElement> = (): void => {
		const input = inputRef.current;
		if (!input) return;
		setIsActive(true);
		input.select();
	};

	const valueToDisplay = value > 0 ? value : '';
	const isOutOfStock = React.useMemo(() => {
		if (!shouldUseAvailability) return false;
		if (!hasAvailability) return true;
		if (!!bundle.minimumQuantity && availableStockLeft < bundle.minimumQuantity) return true;
		if (availableStockLeft === 0) return true;
		return false;
	}, [availableStockLeft, bundle.minimumQuantity, hasAvailability, shouldUseAvailability]);

	if (isDisabled || isOutOfStock) {
		return <DisabledCell />;
	}

	return (
		<>
			<input
				ref={inputRef}
				className={classNames(styles.quantityInput, { [styles.successFlash]: flash })}
				value={valueToDisplay}
				onChange={updateValue}
				onKeyUp={updateValueOnEnterKey}
				onBlur={updateValueOnBlur}
				onFocus={setActiveOnFocus}
			/>
			<FreeAssortmentStockPopup
				masterInfo={master}
				availability={availability}
				realTimeTotalInBasket={realTimeTotalInBasket}
				isSelected={isActive}
				closePopup={() => debouncedSubmitValue()}
				inputRef={inputRef}
			/>
			<IncomingVariantIcon
				variantId={queryInfo.variantId}
				inputValue={value}
				shouldUseStockAvailability={shouldUseAvailability}
			/>
			<IncomingQuantityBadge quantity={inboundQuantity} />
			{shouldUseAvailability && isActive && !isSDO && (
				<StockAvailabilityDesktop availability={availability} quantity={realTimeTotalInBasket} />
			)}
			{isSDO && (
				<DragHandle
					dataType="freeAssortment"
					onDragStart={() => {
						return value;
					}}
					onDrop={(value) => {
						debouncedSubmitValue(value);
						return true;
					}}
				/>
			)}
		</>
	);
};
