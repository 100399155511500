import React, { PropsWithChildren } from 'react';
import { NavigateOptions, useLocation } from 'react-router-dom';
import { useNavigate } from 'library/routing';

export type ParamKeyValuePair = [string, string];

export type URLSearchParamsInit = string | ParamKeyValuePair[] | Record<string, string | string[]> | URLSearchParams;

export const createSearchParams = (init?: URLSearchParamsInit): URLSearchParams => {
	if (init === void 0) {
		init = '';
	}
	const searchParams = new URLSearchParams(
		typeof init === 'string' || Array.isArray(init) || init instanceof URLSearchParams
			? init
			: Object.keys(init).reduce<Array<ParamKeyValuePair>>((memo, key) => {
					const value = init[key];
					return memo.concat(Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]);
			  }, []),
	);
	return searchParams;
};

function getSearchParamsForLocation(locationSearch: string, defaultSearchParams: URLSearchParams | null) {
	const searchParams = createSearchParams(decodeURIComponent(locationSearch));
	if (defaultSearchParams) {
		// Use `defaultSearchParams.forEach(...)` here instead of iterating of
		// `defaultSearchParams.keys()` to work-around a bug in Firefox related to
		// web extensions. Relevant Bugzilla tickets:
		// https://bugzilla.mozilla.org/show_bug.cgi?id=1414602
		// https://bugzilla.mozilla.org/show_bug.cgi?id=1023984
		defaultSearchParams.forEach((_, key) => {
			if (!searchParams.has(key)) {
				defaultSearchParams.getAll(key).forEach((value) => {
					searchParams.append(key, value);
				});
			}
		});
	}
	return searchParams;
}

type UseSearchParamsReturnType = [
	URLSearchParams,
	(
		nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
		navigateOpts?: NavigateOptions,
	) => void,
];

interface SearchParamsContextProps {}

const useSearchParamsState = (): UseSearchParamsReturnType => {
	/**
	 * A convenient wrapper for reading and writing search parameters via the
	 * URLSearchParams interface.
	 */
	const location = useLocation();
	const defaultSearchParamsRef = React.useRef(createSearchParams());
	const hasSetSearchParamsRef = React.useRef(false);

	const searchParams = React.useMemo(() => {
		// Only merge in the defaults if we haven't yet called setSearchParams.
		// Once we call that we want those to take precedence, otherwise you can't
		// remove a param with setSearchParams({}) if it has an initial value
		return getSearchParamsForLocation(
			location.search,
			hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current,
		);
	}, [location.search]);

	const navigate = useNavigate();
	const setSearchParams = React.useCallback(
		(
			nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
			navigateOpts?: NavigateOptions,
		): void => {
			const newSearchParams = createSearchParams(
				typeof nextInit === 'function' ? nextInit(searchParams) : nextInit,
			);
			hasSetSearchParamsRef.current = true;
			navigate('?' + newSearchParams.toString(), navigateOpts);

			getSearchParamsForLocation(newSearchParams.toString(), defaultSearchParamsRef.current);
		},
		[searchParams, navigate],
	);
	return React.useMemo(() => [searchParams, setSearchParams], [searchParams, setSearchParams]);
};

const SearchParamsContext = React.createContext<UseSearchParamsReturnType | null>(null);

export const SearchParamsProvider: React.FunctionComponent<PropsWithChildren<SearchParamsContextProps>> = ({
	children,
}: PropsWithChildren<SearchParamsContextProps>) => {
	const value = useSearchParamsState();

	return <SearchParamsContext.Provider value={value}>{children}</SearchParamsContext.Provider>;
};

export const useSearchParams = (): UseSearchParamsReturnType => {
	const context = React.useContext(SearchParamsContext);

	if (!context) {
		throw new Error('useSearchParams must be used within a SearchParamsProvider');
	}

	return context;
};
