import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	InfiniteData,
	useInfiniteQuery,
	UseInfiniteQueryResult,
	useMutation,
	UseMutationResult,
	useQuery,
} from '@tanstack/react-query';
import { isEmpty, isEqual } from 'lodash';
import { OrderHistoryAccordionLevel } from 'components/features/MyOrdersPage/MyOrdersList/OrderHistoryAccordion';
import { useProductBasketContext } from 'components/features/ProductDetail/ProductBasket/V2/store/BasketContext';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Export as ExportApi } from 'generated/Export';
import { OrderHistory as OrderHistoryApi } from 'generated/OrderHistory';
import { OrderHistoryExport as OrderHistoryExportApi } from 'generated/OrderHistoryExport';
import {
	NoPresentationBrandResult,
	OrderHistoryGroupingRequest,
	OrderHistoryInvoiceResponse,
	OrderHistoryLineExportRequest,
	OrderHistoryLineSearchRequest,
	OrderHistoryLineSearchResponse,
	OrderHistorySearchGroup,
	OrderHistorySort,
	OrderOverviewRequest,
	OrderOverviewSearchResponse,
	PDFDownloadWithSummaryResponseWrapper,
	ProblemDetails,
	SortDirection,
} from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { getInfiniteQueryNextPage } from 'helpers/getInfiniteQueryNextPage';
import { formatTranslation } from 'helpers/stringHelpers';
import { InitialState } from 'store/types';
import { queryKeys, setHeaders } from './apiConfig';
import { useTranslationQuery } from './translations';

const defaultOrderLinesPageSize = 12;

const parseQuery = (orderLinesSearchQuery: OrderHistoryLineSearchRequest) => ({
	phrase: orderLinesSearchQuery.phrase,
	pageSize: orderLinesSearchQuery.pageSize ?? defaultOrderLinesPageSize,
	pageNumber: 1,
	status: orderLinesSearchQuery.status,
	shipToIds: orderLinesSearchQuery.shipToIds,
	sortBy: orderLinesSearchQuery.sortBy ?? OrderHistorySort.EstimatedDeliveryDate,
	sortDirection: orderLinesSearchQuery.sortDirection ?? SortDirection.Descending,
	filters: orderLinesSearchQuery.filters,
	collapseGroups: orderLinesSearchQuery.collapseGroups,
});

export const useMyOrdersListQuery = (
	orderLinesSearchQuery?: OrderHistoryLineSearchRequest,
): UseInfiniteQueryResult<InfiniteData<OrderHistoryLineSearchResponse>, void | NoPresentationBrandResult> => {
	const [searchQuery, setSearchQuery] = useState<OrderHistoryLineSearchRequest | undefined>(
		orderLinesSearchQuery ? parseQuery(orderLinesSearchQuery) : undefined,
	);

	const [reset, setReset] = React.useState(true);
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	React.useEffect(() => {
		if (!orderLinesSearchQuery) return;
		setSearchQuery((prev) => {
			const newQuery: OrderHistoryLineSearchRequest = {
				...parseQuery(orderLinesSearchQuery),
				pageNumber: 1,
			};
			if (!isEqual(prev, newQuery)) {
				setReset(true);
				return newQuery;
			}
			return prev;
		});
	}, [orderLinesSearchQuery]);

	// currentPage should not be part of the key because react-query controls it internally
	const queryKey = React.useMemo(() => {
		return queryKeys.orderHistory.list(segmentationId, searchQuery).queryKey;
	}, [searchQuery, segmentationId]);
	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		refetchInterval: false,
		enabled: !isEmpty(searchQuery),
		queryFn: async ({ pageParam = 1 }) => {
			const orderHistoryApi = new OrderHistoryApi({
				baseApiParams: { headers: setHeaders() },
			});

			const pageNumber = reset ? 1 : pageParam;
			const response = await orderHistoryApi.orderhistorySearchCreate({
				// This cannot be null, as `enabled` is set to true only when searchQuery is defined
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				...searchQuery!,
				pageNumber,
			});

			if (response.ok) {
				setReset(false);
			}
			return response.data;
		},
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		getPreviousPageParam: (firstPage) => {
			const page = firstPage.pagingInformation.currentPage;
			return page > 1 ? page - 1 : undefined;
		},
		initialPageParam: 1,
		retry: false,
	});
};

export const useOrderDetailQuery = (orderNumber?: string) => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const orderHistoryApi = new OrderHistoryApi({
		baseApiParams: { headers: setHeaders() },
	});
	const queryKey = queryKeys.orderHistory.detailLines(segmentationId, orderNumber).queryKey;
	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		enabled: !isEmpty(orderNumber),
		queryFn: async ({ pageParam = 1 }) => {
			const response = await orderHistoryApi.orderhistorySearchDetailsLinesCreate({
				pageNumber: pageParam,
				// This can never be undefined, as `enabled` is set to true only when orderNumber is defined
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				orderNumber: orderNumber!,
			});
			return response.data;
		},
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		getPreviousPageParam: (firstPage) => {
			const page = firstPage.pagingInformation.currentPage;
			return page > 1 ? page - 1 : undefined;
		},
		initialPageParam: 1,
		retry: false,
	});
};

export const useOrderDetailHeaderQuery = (orderNumber?: string) => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const orderHistoryApi = new OrderHistoryApi({
		baseApiParams: { headers: setHeaders() },
	});
	const queryKey = queryKeys.orderHistory.detail(segmentationId, orderNumber).queryKey;
	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		enabled: !isEmpty(orderNumber),
		queryFn: async () => {
			const response = await orderHistoryApi.orderhistorySearchDetailsCreate({
				// This can never be undefined, as `enabled` is set to true only when orderNumber is defined
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				orderNumber: orderNumber!,
			});
			return response.data;
		},
		retry: false,
	});
};

export const useInvoiceExportMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void | ProblemDetails>,
	OrderHistoryInvoiceResponse
> => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	const exportApi = new ExportApi({
		baseApiParams: { headers: setHeaders() },
	});

	return useMutation({
		mutationFn: async (variables) => {
			return exportApi.exportExportList(variables, { format: 'blob' });
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(translations?.shared.exports.exportFailed, {}),
			});
		},
	});
};

export const useMyOrdersPdfMutation = (): UseMutationResult<
	HttpResponse<PDFDownloadWithSummaryResponseWrapper, void | ProblemDetails>,
	HttpResponse<void>,
	OrderHistoryLineExportRequest
> => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useMutation({
		mutationFn: async (variables) => {
			const orderHistoryExportApi = new OrderHistoryExportApi({
				baseApiParams: { headers: setHeaders() },
			});

			return await orderHistoryExportApi.orderhistoryexportListaspdfCreate(variables);
		},
		onError: () => {
			notificationActions.addNotification({
				children: translations?.shared.exports.exportFailed,
				type: AlertTypes.DANGER,
			});
		},
	});
};

export const useMyOrdersExcelMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>,
	OrderHistoryLineExportRequest
> => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useMutation({
		mutationFn: async (variables) => {
			const orderHistoryExportApi = new OrderHistoryExportApi({
				baseApiParams: { headers: setHeaders() },
			});

			return await orderHistoryExportApi.orderhistoryexportExportasexcelCreate(variables, { format: 'blob' });
		},
		onError: () => {
			notificationActions.addNotification({
				children: translations?.shared.exports.exportFailed,
				type: AlertTypes.DANGER,
			});
		},
	});
};

export const useMyOrdersMediaContentMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>,
	OrderHistoryLineExportRequest
> => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useMutation({
		mutationFn: async (variables) => {
			const orderHistoryExportApi = new OrderHistoryExportApi({
				baseApiParams: { headers: setHeaders() },
			});

			return await orderHistoryExportApi.orderhistoryexportExportasmediacontentCreate(variables, {
				format: 'blob',
			});
		},
		onError: () => {
			notificationActions.addNotification({
				children: translations?.shared.exports.exportFailed,
				type: AlertTypes.DANGER,
			});
		},
	});
};

// Subtract 2 years from the current date
const twoYearsAgo = new Date();
twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

// Format the date as a string
const formattedDate2YearsAgo = twoYearsAgo.toISOString().split('T')[0];

export const useGetOrderGroupingsForStyle = (familyId: string, bundleId?: string, masterId?: string) => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const orderHistoryApi = new OrderHistoryApi({
		baseApiParams: { headers: setHeaders() },
	});

	const {
		basket: { basketShipTos },
	} = useProductBasketContext();

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.orderHistory.pdpGroupings(segmentationId, familyId, bundleId, masterId).queryKey,
		queryFn: async () => {
			const response = await orderHistoryApi.orderhistorySearchaccountstatusCreate({
				familyId,
				bundleId,
				masterId,
				shipToIds: basketShipTos,
				searchBackDate: formattedDate2YearsAgo,
			});
			return response.data;
		},
	});
};

export const useOrderHistoryPdpQuery = ({
	familyId,
	shipTo,
	status,
	bundleId,
	masterId,
}: {
	shipTo: string;
	familyId: string;
	bundleId?: string;
	masterId?: string;
	status: OrderHistorySearchGroup;
}) => {
	const defaultOrderLinesPageSize = 12;
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const orderHistoryApi = new OrderHistoryApi({
		baseApiParams: { headers: setHeaders() },
	});
	const queryKey = queryKeys.orderHistory.groupingLines(
		segmentationId,
		shipTo,
		familyId,
		bundleId,
		masterId,
		status,
	).queryKey;
	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		queryFn: async ({ pageParam = 1 }) => {
			const response = await orderHistoryApi.orderhistorySearchCreate({
				pageNumber: pageParam,
				bundleIds: bundleId ? [bundleId] : undefined,
				masterIds: masterId ? [masterId] : undefined,
				familyIds: [familyId],
				shipToIds: [shipTo],
				status,
				pageSize: defaultOrderLinesPageSize,
				collapseGroups: [],
			});
			return response.data;
		},
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		getPreviousPageParam: (firstPage) => {
			const page = firstPage.pagingInformation.currentPage;
			return page > 1 ? page - 1 : undefined;
		},
		initialPageParam: 1,
		retry: false,
	});
};

export const useOrderHistoryGroupingQuery = (
	enabled: boolean = false,
	query?: OrderHistoryGroupingRequest,
	level?: OrderHistoryAccordionLevel,
) => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const orderHistoryApi = new OrderHistoryApi({
		baseApiParams: { headers: setHeaders() },
	});
	const queryKey = queryKeys.orderHistory.groupings(segmentationId, query).queryKey;
	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		enabled: enabled && !isEmpty(query) && level !== OrderHistoryAccordionLevel.Second,
		queryFn: async () => {
			// This can never be null, as `enabled` is set to true only when query is defined
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const response = await orderHistoryApi.orderhistorySearchgroupsCreate(query!);
			return response.data;
		},
		retry: false,
	});
};

export const useOrderOverviewGroupingQuery = (enabled: boolean = false, query?: OrderOverviewRequest) => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const orderHistoryApi = new OrderHistoryApi({
		baseApiParams: { headers: setHeaders() },
	});
	const queryKey = queryKeys.orderHistory.orders(segmentationId, query).queryKey;

	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		enabled: enabled && !isEmpty(query),
		queryFn: async ({ pageParam = 1 }) => {
			const response = await orderHistoryApi.orderhistorySearchoverviewCreate({
				// This can never be null, as `enabled` is set to true only when query is defined
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				...query!,
				pageNumber: pageParam,
			});

			return response.data;
		},
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		getPreviousPageParam: (firstPage) => {
			const page = firstPage.pagingInformation.currentPage;
			return page > 1 ? page - 1 : undefined;
		},
		select: (data) => {
			return data.pages.reduce<OrderOverviewSearchResponse | undefined>((acc, v) => {
				if (acc) {
					acc.overviewLines = acc
						.overviewLines.concat(...v.overviewLines);
					return acc;
				}
				return {...v};
			}, undefined);
		},
		initialPageParam: 1,
		retry: false,
	});
};
