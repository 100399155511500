import React from 'react';
import { useLayoutQuery } from 'api/layout';
import { useShiptoSelectedListQuery } from 'api/shipTo';
import { useTranslationQuery } from 'api/translations';
import { Icon, Link } from 'components/shared';
import { Area, FeatureFlag, PageType } from 'generated/data-contracts';
import styles from './MyCmi.module.scss';

export const MyCmi: React.FunctionComponent = () => {
	const { data: layout } = useLayoutQuery();
	const { data: translations } = useTranslationQuery();
	const { data: selectedShipTos } = useShiptoSelectedListQuery();

	const hasCmiFeatureFlag = layout?.userFeatureFlags.includes(FeatureFlag.Cmi);

	const cmiPageUrl = layout?.staticLinks.find((link) => link.pageType === PageType.Cmi)?.url;

	if (!cmiPageUrl || !hasCmiFeatureFlag || !selectedShipTos?.cmiEnabled) {
		return null;
	}

	return (
		<Link
			to={cmiPageUrl}
			route={{
				area: Area.StaticPages,
				pageType: PageType.Cmi,
				externalRoute: cmiPageUrl,
			}}
			className={styles.link}
		>
			<div className={styles.label}>
				<Icon name={'cmi'} size={'md'} />
				<span className={styles.title}>{translations?.cmi.heading}</span>
			</div>
			<Icon name={'chevronDown'} size={'lg'} rotate={270} />
		</Link>
	);
};
