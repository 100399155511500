import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'library/routing';
import { isEmpty, uniqBy } from 'lodash';
import { useProductBasketQuery } from '../ProductBasket/V2/api/productBasket';
import { useProductDetailQuery } from 'api/product';
import { useProductTrackingQuery } from 'api/tracking';
import {
	ACTIVE_DATE_SEARCH_PARAM,
	SELECT_VIEW_SEARCH_PARAM,
	STYLE_OPTION_NUMBER_SEARCH_PARAM,
} from 'components/fragments/Basket/helpers/urlParams';
import {
	AssortmentType,
	BasketFamily,
	BasketFilterResponse,
	BasketFilterType,
	BasketGroupingType,
	Grouping,
	ProductMasterResponse,
	ProductResponse,
	SplashResponse,
	SplashType,
} from 'generated/data-contracts';
import { trackProduct } from 'helpers/tracking';
import { useViewportSize } from 'helpers/useViewportSize';
import { ALL_DELIVERY_DATE_VALUE, NOW_DELIVERY_DATE_VALUE } from '../ProductBasket/V2/shared/basketTypes';
import { convertMasterIdToStyleOptionId, convertStyleOptionIdToMasterId } from '../productDetailUtils';

interface UseProductDetailContextReturnType {
	product: ProductResponse;
	styleOption: {
		availableMasters: ProductMasterResponse[];
		setStyleOptionNumber: (masterId?: string) => void;
		styleOptionNumber?: string;
		currentMaster: ProductMasterResponse | undefined;
	};
	assortmentView: {
		availableViews: AssortmentType[];
		setSelectedView: (assortmentType?: AssortmentType) => void;
		selectedView?: AssortmentType;
	};
	deliveryDates: {
		deliveryDateList: BasketFilterResponse[];
		setDeliveryDate: (deliveryDate?: string) => void;
		deliveryDate?: string;
		sanitizedDeliveryDate?: string;
	};
}

interface ProductDetailContextContextProps {
	id: string;
}

const useProductDetailContextState = ({ id }: ProductDetailContextContextProps): UseProductDetailContextReturnType => {
	const { data: product } = useProductDetailQuery(id);
	const { isMobile } = useViewportSize();
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const [deliveryDate, setDeliveryDate] = React.useState<string | undefined>(
		searchParams.get(ACTIVE_DATE_SEARCH_PARAM) ?? undefined,
	);
	const [sanitizedDeliveryDate, setSanitizedDeliveryDate] = React.useState<string | undefined>(
		deliveryDate === ALL_DELIVERY_DATE_VALUE ? undefined : deliveryDate,
	);
	const [selectedView, setSelectedView] = React.useState<AssortmentType | undefined>(
		searchParams.get(SELECT_VIEW_SEARCH_PARAM) as AssortmentType,
	);
	const [styleOptionNumber, setStyleOptionNumber] = React.useState<string | undefined>(
		searchParams.get(STYLE_OPTION_NUMBER_SEARCH_PARAM) ?? undefined,
	);

	React.useEffect(() => {
		setSanitizedDeliveryDate((prev) => (deliveryDate === ALL_DELIVERY_DATE_VALUE ? prev : deliveryDate));
	}, [deliveryDate]);

	React.useEffect(() => {
		setSearchParams(
			(prev) => {
				const updatedParams = new URLSearchParams(prev.toString());
				[
					{
						param: ACTIVE_DATE_SEARCH_PARAM,
						value:
							deliveryDate === ALL_DELIVERY_DATE_VALUE
								? prev.get(ACTIVE_DATE_SEARCH_PARAM)
								: deliveryDate,
					},
					{ param: SELECT_VIEW_SEARCH_PARAM, value: selectedView },
					{
						param: STYLE_OPTION_NUMBER_SEARCH_PARAM,
						value: styleOptionNumber ? convertMasterIdToStyleOptionId(styleOptionNumber) : undefined,
					},
				].forEach(({ param, value }) => {
					if (!value || isEmpty(value)) {
						updatedParams.delete(param);
						return;
					}
					updatedParams.set(param, value);
				});
				return updatedParams.toString();
			},
			{
				replace: true,
				state: location.state,
			},
		);
	}, [deliveryDate, selectedView, styleOptionNumber, setSearchParams, location.state]);

	const { data: trackingData } = useProductTrackingQuery(styleOptionNumber || product?.masters[0].id);
	let groupingTypeObject: BasketGroupingType | undefined;
	if (isMobile) groupingTypeObject = BasketGroupingType.ShipTo;
	const { data } = useProductBasketQuery({
		familyId: id,
		basketDeliveryDate: sanitizedDeliveryDate,
		basketLines: [],
		groupingType: groupingTypeObject,
		shippingFilter: BasketFilterType.DeliveryDate,
	});

	React.useEffect(() => {
		if (trackingData) trackProduct(trackingData);
	}, [trackingData]);

	if (!product || !data) {
		throw new Error('Product not initialized');
	}

	const productDiscount = React.useMemo((): SplashResponse[] | undefined => {
		if (!data) return;
		const family = data.basketContents.lookups.families[id];
		if (!family) return;
		return family.splashes.filter((r) => r.type === SplashType.Discount);
	}, [data, id]);

	const getMastersFromMasterId = React.useCallback(
		(masterId: string, isFreeAssortment: boolean = false): ProductMasterResponse => {
			const master = data.basketContents.lookups.masters[masterId];
			let productImageSplashes = master.splashes ?? [];
			if (!isFreeAssortment || deliveryDate !== NOW_DELIVERY_DATE_VALUE) {
				productImageSplashes = productImageSplashes
					.filter((r) => r.type !== SplashType.Discount)
					.concat(productDiscount ?? []);
			}
			return {
				colour: master.colour,
				hasImage: master.images !== undefined,
				id: masterId,
				name: product.name,
				images: master.images,
				splashes: productImageSplashes,
			};
		},
		[data, deliveryDate, product.name, productDiscount],
	);

	const getMastersFromBundle = React.useCallback(
		(bundleId: string): ProductMasterResponse[] => {
			const bundle = data.basketContents.lookups.bundles[bundleId];
			if (!bundle) return [];
			const masters = bundle.masters.map(({ id }) => getMastersFromMasterId(id));
			return masters;
		},
		[data, getMastersFromMasterId],
	);

	const availableMasters = React.useMemo((): ProductMasterResponse[] => {
		if (!deliveryDate || deliveryDate === ALL_DELIVERY_DATE_VALUE) {
			return product.masters;
		}

		// Gets all the families from the basket contents
		const getLeavesFromBasketContents = (basketContents: Grouping): ProductMasterResponse[] => {
			if (basketContents.childType === BasketGroupingType.Family) {
				return basketContents.children.flatMap((family: BasketFamily) => {
					if (selectedView === AssortmentType.Boxes) {
						return family.boxBundles.flatMap((bundle) => getMastersFromBundle(bundle.id));
					}
					return family.freeAssortmentMasters.flatMap((freeAssortment) =>
						getMastersFromMasterId(freeAssortment.id, true),
					);
				});
			}
			return basketContents.children.flatMap(getLeavesFromBasketContents);
		};
		return uniqBy(getLeavesFromBasketContents(data.basketContents.grouping), (r) => r.id);
	}, [data, deliveryDate, getMastersFromBundle, getMastersFromMasterId, product.masters, selectedView]);

	const currentMaster = React.useMemo<ProductMasterResponse | undefined>((): ProductMasterResponse | undefined => {
		let master: ProductMasterResponse | undefined;
		if (styleOptionNumber) {
			master = availableMasters.find(
				({ id }) => convertStyleOptionIdToMasterId(id) === convertStyleOptionIdToMasterId(styleOptionNumber),
			);
		}
		return master;
	}, [styleOptionNumber, availableMasters]);

	React.useEffect(() => {
		setStyleOptionNumber((prev) => {
			if (!availableMasters.length) return prev;
			if (
				!prev ||
				!availableMasters.find(
					({ id }) => convertStyleOptionIdToMasterId(id) === convertStyleOptionIdToMasterId(prev),
				)
			) {
				return convertMasterIdToStyleOptionId(availableMasters[0].id);
			}
			return prev;
		});
	}, [availableMasters]);

	React.useEffect(() => {
		const availableViews = data.basketContents.lookups.availableAssortmentTypes;
		setSelectedView((prev) => {
			if (!availableViews || !availableViews.length) return prev;
			if (!prev || !availableViews.includes(prev)) {
				return availableViews[0];
			}
			return prev;
		});
	}, [data.basketContents.lookups.availableAssortmentTypes]);

	React.useEffect(() => {
		const availableDates = data.basketContents.lookups.shippingFilters;
		setDeliveryDate((prev) => {
			if (!availableDates || !availableDates.length) return prev;
			if (!prev || !availableDates.map((r) => r.value).includes(prev)) {
				const newDeliveryDate = availableDates.find((ad) => ad.isSelected) ?? availableDates[0];
				return newDeliveryDate?.value;
			}
			return prev;
		});
	}, [data.basketContents.lookups.shippingFilters]);

	return {
		product,
		deliveryDates: {
			setDeliveryDate,
			deliveryDate,
			sanitizedDeliveryDate,
			deliveryDateList: data.basketContents.lookups.shippingFilters,
		},
		assortmentView: {
			availableViews: data.basketContents.lookups.availableAssortmentTypes,
			setSelectedView,
			selectedView,
		},
		styleOption: {
			setStyleOptionNumber: setStyleOptionNumber,
			styleOptionNumber: styleOptionNumber,
			availableMasters: availableMasters,
			currentMaster,
		},
	};
};

const ProductDetailContextContext = React.createContext<UseProductDetailContextReturnType | null>(null);

export const ProductDetailContextProvider: React.FunctionComponent<
	PropsWithChildren<ProductDetailContextContextProps>
> = ({ children, ...props }: PropsWithChildren<ProductDetailContextContextProps>) => {
	const value = useProductDetailContextState(props);

	return <ProductDetailContextContext.Provider value={value}>{children}</ProductDetailContextContext.Provider>;
};

export const useProductDetailContext = (): UseProductDetailContextReturnType => {
	const context = React.useContext(ProductDetailContextContext);

	if (!context) {
		throw new Error('useProductDetailContext must be used within a ProductDetailContextProvider');
	}

	return context;
};
