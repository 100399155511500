import React from 'react';
import { useViewportSize } from 'helpers';
import {
	OrderHistorySearchGroupingTypeExtended,
	OrderHistorySearchGroupingTypeExtendedValue,
} from 'components/features/MyOrdersPage/hooks/useGroupingFilter';

export type OrderHistoryViewportSize = 'lg' | 'sm' | 'md';

export const useOrderHistoryViewportSize = (
	currentGrouping?: OrderHistorySearchGroupingTypeExtended | undefined,
): OrderHistoryViewportSize => {
	const sizes = useViewportSize();
	const size = React.useMemo((): OrderHistoryViewportSize => {
		switch (sizes.size) {
			case 'xs':
				return 'sm';
			case 'sm':
			case 'md':
				return currentGrouping === OrderHistorySearchGroupingTypeExtendedValue.Overview ? 'sm' : 'md';
			default:
				return 'lg';
		}
	}, [currentGrouping, sizes.size]);
	return size;
};
