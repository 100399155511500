import React from 'react';
import classNames from 'classnames';
import { FavouriteIcon } from 'components/shared/FavouriteIcon';
import { useProductCardContext } from '../../context/ProductCardContext';
import styles from './AddFavouriteIcon.module.scss';

export interface AddFavouriteIconProps {
	className?: string;
}

export const AddFavouriteIcon: React.FunctionComponent<AddFavouriteIconProps> = ({ className }) => {
	const { activeMaster, isSkeleton } = useProductCardContext();

	if (isSkeleton) {
		return null;
	}
	return (
		<FavouriteIcon
			className={classNames(className, styles.favIcon)}
			deliveryMonth={activeMaster?.deliveryDate}
			productMasterId={activeMaster?.id}
			isFreeAssortment={activeMaster?.isFreeAssortment}
			iconSize="md"
			buttonSize="sm"
			isFavourite={activeMaster?.isFavourite}
		/>
	);
};
