import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useSearchParams } from 'library/routing';
import { isEqual, isEmpty, uniq } from 'lodash';
import { useOrderHistoryGroupingQuery, useOrderOverviewGroupingQuery } from 'api/myOrders';
import { useTranslationQuery } from 'api/translations';
import {
	OrderHistoryViewportSize,
	useOrderHistoryViewportSize,
} from 'components/shared/hooks/useOrderHistoryViewportSize';
import {
	ExportInformationResponse,
	FilterRequest,
	GroupingResponse,
	OrderHistoryGroupingRequest,
	OrderHistorySearchGroup,
	OrderHistorySearchGroupingType,
	OrderHistorySort,
	OrderOverviewRequest,
	OrderOverviewSearchResponse,
	SortDirection,
	SortingResponseOrderHistorySort,
} from 'generated/data-contracts';
import { getOrderHistoryDesktopHeader } from '../helpers/getOrderHistoryDesktopHeader';
import { OrderListUrlParamKeys, PREDEFINED_ORDERLIST_URL_PARAMS } from '../constants/OrderListUrlParamKeys';
import { useAccountsFilter } from '../hooks/useAccountsFilter';
import { UseFilterActionsResponse, useFilterActions } from '../hooks/useFilterActions';
import {
	GroupingOption,
	OrderHistorySearchGroupingTypeExtended,
	OrderHistorySearchGroupingTypeExtendedValue,
	useGroupingFilter,
} from '../hooks/useGroupingFilter';
import { useSortingOptions } from '../hooks/useSortingOptions';

type OrderHistoryLayoutInfo = {
	totalValue?: string;
	totalQuantity?: number;
};

type UseOrdersPageContextResponse = {
	header: { label: string; sortBy?: OrderHistorySort }[];
	ordersResponse?: UseInfiniteQueryResult<OrderOverviewSearchResponse | undefined, Error>;
	overviewResponse?: OrderOverviewSearchResponse;
	shipToGroupings?: GroupingResponse[];
	status: OrderHistorySearchGroup;
	layoutInfo: OrderHistoryLayoutInfo;
	isLoading: boolean;
	size: OrderHistoryViewportSize;
	changeSort: (sort: OrderHistorySort, sortDirection: SortDirection) => void;
	groupingOptions: GroupingOption[];
	currentGrouping: OrderHistorySearchGroupingTypeExtended;
	changeGrouping: (grouping: OrderHistorySearchGroupingTypeExtended) => void;
	filters?: UseFilterActionsResponse;
	currentSort?: SortingResponseOrderHistorySort;
	sortOptions?: SortingResponseOrderHistorySort[];
	selectedShipTos: string[] | undefined;
	exportInformation?: ExportInformationResponse;
	isOnOrdersOverview: boolean;
};

const EXCLUDED_FROM_ACTIVE: string[] = [OrderListUrlParamKeys.ShipTo];
const FILTERED_SEARCH_PARAM_KEYS = PREDEFINED_ORDERLIST_URL_PARAMS.filter((r) => !EXCLUDED_FROM_ACTIVE.includes(r));
const parseQueryParameters = (
	searchParams: URLSearchParams,
	selectedFilters: FilterRequest[] = [],
	selectedShipTos?: string[],
): OrderHistoryGroupingRequest => {
	const filters = selectedFilters.filter((filter) => {
		if (!filter.filter) return true;
		return !PREDEFINED_ORDERLIST_URL_PARAMS.includes(filter.filter);
	});
	return {
		phrase: searchParams.get(OrderListUrlParamKeys.SearchPhrase) || '',
		groupingType: OrderHistorySearchGroupingType.ShipTo, // This is hardcoded intentionally because this is the default grouping type
		collapseGroups: [],
		status: (searchParams.get(OrderListUrlParamKeys.Tab) as OrderHistorySearchGroup) ?? OrderHistorySearchGroup.All,
		shipToIds: selectedShipTos?.length ? selectedShipTos : undefined,
		filters,
	};
};

const useOrdersPageState = (): UseOrdersPageContextResponse => {
	const { changeGrouping, groupingOptions, currentGrouping } = useGroupingFilter();
	const { changeSort, currentSort, setSortOptions, sortOptions } = useSortingOptions();
	const size = useOrderHistoryViewportSize(currentGrouping);
	const [searchParams] = useSearchParams();
	const { data: translations } = useTranslationQuery();
	const [selectedShipTos, setSelectedShipTos] = useState<string[] | undefined>();

	const [searchQuery, setSearchQuery] = React.useState<OrderHistoryGroupingRequest>();

	const isOverviewEnabled = currentGrouping === OrderHistorySearchGroupingTypeExtendedValue.Overview;
	const { data: shipToResponse, isLoading: isLoadingGroup } = useOrderHistoryGroupingQuery(
		!isOverviewEnabled,
		searchQuery,
	);
	const orderOverviewQuery: OrderOverviewRequest | undefined = searchQuery
		? {
				...searchQuery,
				sortBy: currentSort?.sortBy,
				sortDirection: currentSort?.sortDirection,
		  }
		: undefined;
	const ordersResponse = useOrderOverviewGroupingQuery(isOverviewEnabled, orderOverviewQuery);
	const { data: overviewResponse, isLoading: isLoadingOverview } = ordersResponse;

	useEffect(() => {
		setSortOptions(isOverviewEnabled ? overviewResponse?.sorting : shipToResponse?.sorting);
	}, [isOverviewEnabled, overviewResponse?.sorting, setSortOptions, shipToResponse?.sorting]);

	const header = useMemo(
		() => getOrderHistoryDesktopHeader(isOverviewEnabled, translations),
		[isOverviewEnabled, translations],
	);

	const accountsFilter = useAccountsFilter(selectedShipTos);
	const filtersFromResponse = React.useMemo(() => {
		return isOverviewEnabled
			? [accountsFilter, ...(overviewResponse?.filters ?? [])]
			: [accountsFilter, ...(shipToResponse?.filters ?? [])];
	}, [accountsFilter, isOverviewEnabled, overviewResponse?.filters, shipToResponse?.filters]);

	const filters = useFilterActions(filtersFromResponse, FILTERED_SEARCH_PARAM_KEYS, EXCLUDED_FROM_ACTIVE);

	useEffect(() => {
		setSearchQuery((prev) => {
			const newQuery = parseQueryParameters(searchParams, filters?.selectedFilters, selectedShipTos);
			if (!isEqual(prev, newQuery)) return newQuery;
			return prev;
		});
	}, [filters?.selectedFilters, searchParams, selectedShipTos]);

	useEffect(() => {
		const selectedShipTos = filters?.selectedFilters
			.filter(
				(filter): filter is { filter: string; value: string } =>
					filter.filter === OrderListUrlParamKeys.ShipTo && !isEmpty(filter.value),
			)
			.map((filter) => filter.value);
		if (selectedShipTos) {
			setSelectedShipTos(uniq(selectedShipTos));
		}
	}, [filters?.selectedFilters, setSelectedShipTos]);

	const layoutInfo = React.useMemo(() => {
		if (isOverviewEnabled) {
			return {
				totalValue: ordersResponse?.data?.totalValue ?? undefined,
				totalQuantity: ordersResponse?.data?.totalQuantity,
			};
		}

		return {
			totalValue: shipToResponse?.totalValue ?? undefined,
			totalQuantity: shipToResponse?.totalQuantity,
		};
	}, [
		isOverviewEnabled,
		ordersResponse?.data?.totalQuantity,
		ordersResponse?.data?.totalValue,
		shipToResponse?.totalQuantity,
		shipToResponse?.totalValue,
	]);

	return {
		header,
		overviewResponse,
		ordersResponse,
		shipToGroupings: shipToResponse?.groupings,
		layoutInfo,
		status: (searchParams.get(OrderListUrlParamKeys.Tab) as OrderHistorySearchGroup) ?? OrderHistorySearchGroup.All,
		isLoading: isOverviewEnabled ? isLoadingOverview : isLoadingGroup,
		selectedShipTos,
		filters,
		size,
		changeGrouping,
		groupingOptions,
		currentGrouping,
		changeSort,
		currentSort,
		sortOptions,
		exportInformation: shipToResponse?.exportInformation,
		isOnOrdersOverview: isOverviewEnabled,
	};
};

const OrdersPageContext = React.createContext<UseOrdersPageContextResponse | null>(null);

export const OrdersPageContextProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
	const ordersActions = useOrdersPageState();
	return <OrdersPageContext.Provider value={ordersActions}>{children}</OrdersPageContext.Provider>;
};

export const useOrdersPageContext = (): UseOrdersPageContextResponse => {
	const context = useContext(OrdersPageContext);

	if (!context) {
		throw new Error('useOrdersActionsContext must be used within a OrdersActionsContextProvider');
	}

	return context;
};
