import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { Image } from 'components/shared/Image';
import { DefaultImage } from 'components/shared/Image/DefaultImage';
import { LoadingBar } from 'components/shared/LoadingBar';
import { ArrowButton } from 'components/shared/Slider/ArrowButton';
import { useScrollable } from 'components/shared/hooks/useScrollable';
import {
	BundleThumbnailResponse,
	ColourResponse,
	ProductImageResponse,
	ResourceLocation,
} from 'generated/data-contracts';
import styles from './ProductThumbList.module.scss';

export interface ThumbProps {
	id?: string;
	image?: ProductImageResponse | BundleThumbnailResponse;
	hasImage?: boolean;
	colour?: ColourResponse;
	url?: string;
	isEnabledForCmi?: boolean;
}
interface ProductThumbListProps {
	items?: ThumbProps[];
	itemsToShow?: number;
	isLazyImage?: boolean;
	className?: string;
	route?: ResourceLocation;
	hasHoverEffect?: boolean;
	isSkeleton?: boolean;
	itemClassName?: string;
	handleThumbnailPressed?: (index: number) => void;
	setCurrentActiveMaster: (id: string | undefined) => void;
}

export const ProductThumbList: FC<ProductThumbListProps> = ({
	items,
	isLazyImage = false,
	className,
	itemClassName,
	route,
	hasHoverEffect,
	setCurrentActiveMaster,
	handleThumbnailPressed,
	isSkeleton,
}) => {
	const { scrollableRefs, scrollableTo, scrollableClasses, scrollableActionsStates } = useScrollable({
		scrollInPx: 100,
		hideHorizontalScroll: true,
	});
	const translations = useTranslationQuery().data;

	if (isSkeleton) {
		return (
			<section className={classNames(styles.wrapper, 'u-scroll-bar-hidden')}>
				<ul className={classNames(styles.thumbnailList, className, 'u-scroll-bar-hidden')}>
					{Array.from({ length: 4 }, (_, i) => (
						<li className={styles.thumbnailListItem} key={i}>
							<LoadingBar width="100%" height="100%" />
						</li>
					))}
				</ul>
			</section>
		);
	}
	if (!items || !items.length) {
		return null;
	}

	const handleActiveMaster = setCurrentActiveMaster;

	return (
		<section className={classNames(styles.wrapper, 'u-scroll-bar-hidden')} ref={scrollableRefs.container}>
			<ul
				className={classNames(
					styles.thumbnailList,
					className,
					'u-scroll-bar-hidden',
					scrollableClasses.wrapper,
				)}
				ref={scrollableRefs.wrapper}
			>
				{items.map((item, i) => {
					const { image } = item;

					return (
						<li
							className={styles.thumbnailListItem}
							key={`${item.id}_${i}`}
							{...(handleThumbnailPressed && { onClick: (): void => handleThumbnailPressed(i) })}
						>
							<Button
								hasNoStyles
								onClick={(): void => handleActiveMaster(item.id)}
								className={classNames(styles.thumbnailUrl, itemClassName, {
									[styles.hasHoverEffect]: item.hasImage && hasHoverEffect,
								})}
								state={route}
							>
								{item.isEnabledForCmi && <Icon name="checkMark" size="xs" className={styles.check} />}
								{!item.hasImage ? (
									<div
										className={styles.thumbnailListColor}
										style={{ backgroundColor: item.colour?.hexCode }}
									>
										{!item.colour?.hexCode && (
											<DefaultImage isThumbnail isSmallImage className={styles.defaultImage} />
										)}
									</div>
								) : (
									image && (
										<Image
											className={classNames(styles.thumbnailImage, {
												[styles.hasHoverEffect]: hasHoverEffect,
											})}
											desktopSrc={image.size100}
											desktopSrc2x={image.size100}
											mobileSrc={image.size100}
											mobileSrc2x={image.size100}
											altText={image.altText || ''}
											isLazy={isLazyImage}
										/>
									)
								)}
							</Button>
						</li>
					);
				})}
			</ul>
			<nav
				className={classNames(styles.navigation, scrollableClasses.navigation)}
				aria-label={translations?.shared.navigation.slider}
			>
				<ArrowButton
					className={classNames(styles.arrow, styles.left)}
					onClick={scrollableTo.left}
					hasNoStyles
					disabled={scrollableActionsStates.left}
					prev
					variant="ghost"
					size="sm"
					iconProps={{
						size: 'md',
					}}
				/>
				<ArrowButton
					className={classNames(styles.arrow)}
					onClick={scrollableTo.right}
					disabled={scrollableActionsStates.right}
					hasNoStyles
					next
					variant="ghost"
					size="sm"
					iconProps={{
						size: 'md',
					}}
				/>
			</nav>
		</section>
	);
};
