import React, { FC } from 'react';
import { OrderHistoryAccordionLevel, OrderHistoryAccordion } from '..';
import classNames from 'classnames';
import { useOrderHistoryGroupingQuery } from 'api/myOrders';
import { PREDEFINED_ORDERLIST_URL_PARAMS } from 'components/features/MyOrdersPage/constants/OrderListUrlParamKeys';
import { useOrdersPageContext } from 'components/features/MyOrdersPage/context/OrdersPageContext';
import {
	GroupingRequest,
	GroupingResponse,
	OrderHistoryGroupingRequest,
	OrderHistorySearchGroupingType,
} from 'generated/data-contracts';
import { OrderLineList } from '../../OrderLineList';
import { createHeaderId } from '../OrderHistoryAccordionSummary';
import styles from './OrderHistoryAccordionContent.module.scss';

export interface OrderHistoryAccordionContentProps {
	className?: string;
	level: OrderHistoryAccordionLevel;
	type: OrderHistorySearchGroupingType;
	lastGroupings?: GroupingRequest[];
	grouping: GroupingResponse;
}
export const OrderHistoryAccordionContent: FC<OrderHistoryAccordionContentProps> = ({
	className,
	grouping,
	level,
	type,
	lastGroupings = [],
}) => {
	const { status, filters, currentGrouping, selectedShipTos } = useOrdersPageContext();
	const nextGroupingType: OrderHistorySearchGroupingType = React.useMemo(() => {
		switch (type) {
			case OrderHistorySearchGroupingType.ShipTo:
				return currentGrouping as OrderHistorySearchGroupingType;
			case OrderHistorySearchGroupingType.DeliveryMonth:
				return OrderHistorySearchGroupingType.Category;
			case OrderHistorySearchGroupingType.Category:
				return OrderHistorySearchGroupingType.DeliveryMonth;
		}
	}, [currentGrouping, type]);
	const expandedGroups: GroupingRequest[] = React.useMemo(
		() => [
			...lastGroupings,
			{
				type,
				value: grouping.id ?? '',
			} as GroupingRequest,
		],
		[lastGroupings, grouping.id, type],
	);
	const selectedShipTo = expandedGroups.find((group) => group.type === OrderHistorySearchGroupingType.ShipTo)?.value;
	const query: OrderHistoryGroupingRequest = {
		groupingType: nextGroupingType,
		shipToIds: selectedShipTo ? [selectedShipTo] : selectedShipTos,
		status,
		phrase: filters?.searchPhrase,
		collapseGroups: expandedGroups,
		filters: filters?.selectedFilters.filter((filter) => {
			if (!filter.filter) return true;
			return !PREDEFINED_ORDERLIST_URL_PARAMS.includes(filter.filter);
		}),
	};

	const enabled =
		query.groupingType === OrderHistorySearchGroupingType.Category ||
		query.groupingType === OrderHistorySearchGroupingType.DeliveryMonth;

	const { data: groupings } = useOrderHistoryGroupingQuery(enabled, query, level);

	if (level === OrderHistoryAccordionLevel.Second || currentGrouping === OrderHistorySearchGroupingType.ShipTo) {
		return <OrderLineList collapseGroups={expandedGroups} />;
	}

	const nextLevel = OrderHistoryAccordionLevel[OrderHistoryAccordionLevel[level + 1]];
	const headerId = createHeaderId(level, grouping);

	const getHeaderHeight = () => {
		const header = document.getElementById(headerId);
		if (!header) return '0px';
		return `${header.getBoundingClientRect().height}px`;
	};

	return (
		<section
			className={classNames(className, styles.container)}
			style={
				{
					'--summary-height': getHeaderHeight(),
				} as React.CSSProperties
			}
		>
			{groupings?.groupings.map((group) => (
				<OrderHistoryAccordion
					key={group.id}
					type={nextGroupingType}
					lastGroupings={expandedGroups}
					level={nextLevel}
					grouping={group}
				/>
			))}
		</section>
	);
};
